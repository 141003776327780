import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = function Logo() {
  return <img src="https://vintup.net/wp-content/uploads/2023/10/vintup-grand-logo-final-e1702229656821.png" alt="Logo Vintup" style={{
    width: "200px",
    height: "auto",
    marginBottom: "-15px",
    marginTop: "-10px"
  }} />;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      